export const General = {
    api: {
        login: "/sign-in",
        logout: "/logout",
        forgot: "/forgot-password",
        reset: "/reset-password",
        change: "/ChangePassword",
        dashboard: "/DashboardCount/GetDashboardCountDetails",
        publicSpeaker: "/public-speakers",
        user: "/user-list",
        seatRange: "/seat-range",
        removeAccount: '/user/profile',
        needHelp: 'need-help',
        verifyToken: '/product/approval/{token}',
        verifyServiceToken: '/service/approval/{token}',
        approveProduct: '/product/approval/action',
        approveService: '/service/approval/action',
        accontConfirm: '/account/confirm/{token}',
        unit: '/unit',
        dashboardCount: '/dashboard-count',
        notification: '/get-notification',
        all_notification: '/notification-list?page={page}',
        //        advanceSearch: '/user/advance-search'
    },
    web: {
        terms: "/#/terms",
    }
};

export const Dashboard = {
    api: {
        list: "/dashboard"
    },
    web: {
        list: "/dashboard"
    }
};
// noinspection JSDuplicatedDeclaration
export const country = {
    api: {
        list: "/countries/list",
        view: "/countries/{id}",
    },
    web: {
        create: "/countries/create",
        edit: "/countries/{id}",
        update: "/countries/{id}",
        delete: "/countries/{id}",
    }
};
export const calendar = {
    api: {
        list: "/calendar/list",
        view: "/calendar/view/{id}",
    },
    web: {
        create: "/calendar/create",
        edit: "/calendar/edit/{id}",
        update: "/calendar/update/{id}",
        delete: "/calendar/delete/{id}",
    }
};
export const plan = {
    api: {
        list: "/plan/list",
        view: "/plan/view/{id}",
    },
    web: {
        create: "/plan/create",
        edit: "/plan/update/{id}",
        delete: "/plan/delete/{id}",
    }
};


export const announcement = {
    api: {
        list: "/announcements/list",
        view: "/announcements/{id}",
    },
    web: {
        create: "/announcements/create",
        delete: "/announcements/{id}",
    }
};
export const version = {
    api: {
        list: "/version/list",
        view: "/version/{id}",
    },
    web: {
        create: "/version/create",
        delete: "/version/{id}",
    }
};
export const status = {
    api: {
        list: "/status",
    }
}
export const worktype = {
    api: {
        list: "/work-types/list",
        view: "/work-types/{id}",
    },
    web: {
        create: "/work-types/create",
        edit: "/work-types/{id}",
        update: "/work-types/{id}",
        delete: "/work-types/{id}",
    }
};
export const groupcategorytype = {
    api: {
        list: "/group-category-types/list",
        view: "/group-category-types/{id}",
    },
    web: {
        create: "/group-category-types/create",
        edit: "/group-category-types/{id}",
        update: "/group-category-types/{id}",
        delete: "/group-category-types/{id}",
    }
};
export const businessTraveller = {
    api: {
        list: "/business-traveller/list",
        view: "/business-traveller/view/{id}",
    },
    web: {
        create: "/business-traveller/create",
        edit: "/business-traveller/edit/{id}",
        status: "/business-traveller/status/{id}",
        update: "/business-traveller/update/{id}",
        delete: "/business-traveller/delete/{id}",
    }
};

//Business Travel Report List
export const BTReportList = {
    api: {
        list: "/business-traveller/report/list/{id}",
        view: "/business-traveller/report/view/{id}",
    },
    web: {
        create: "/business-traveller/report/create",
        edit: "/business-traveller/report/edit/{id}",
        update: "/business-traveller/report/update/{id}",
        delete: "/business-traveller/report/delete/{id}",
    }
};
//Business Travel Report List
export const BTResponseList = {
    api: {
        list: "/business-traveller/response/list/{id}",
        view: "/business-traveller/response/view/{id}",
    },
    web: {
        create: "/business-traveller/response/create",
        edit: "/business-traveller/response/edit/{id}",
        update: "/business-traveller/response/update/{id}",
        delete: "/business-traveller/response/delete/{id}",
    }
};

export const companytype = {
    api: {
        list: "/company-types/list",
        view: "/company-types/{id}",
    },
    web: {
        create: "/company-types/create",
        edit: "/company-types/{id}",
        update: "/company-types/{id}",
        delete: "/company-types/{id}",
    }
};
export const price = {
    api: {
        list: "/price/list",
        view: "/price/{id}",
    },
    web: {
        create: "/price/create",
        edit: "/price/{id}",
        update: "/price/{id}",
        delete: "/price/{id}",
    }
};
export const collegeDepartment = {
    api: {
        list: "/college-department/list",
        view: "/college-department/{id}",
    },
    web: {
        create: "/college-department/create",
        edit: "/college-department/{id}",
        update: "/college-department/{id}",
        delete: "/college-department/{id}",
    }
};
export const universities = {
    api: {
        list: "/university/list",
        view: "/university/{id}",
    },
    web: {
        create: "/university/create",
        edit: "/university/{id}",
        update: "/university/{id}",
        delete: "/university/{id}",
    }
};
export const programme = {
    api: {
        list: "/programme/list",
        view: "/programme/{id}",
    },
    web: {
        create: "/programme/create",
        edit: "/programme/{id}",
        update: "/programme/{id}",
        delete: "/programme/{id}",
    }
};
export const college = {
    api: {
        list: "/college/list",
        view: "/college/{id}",
    },
    web: {
        create: "/college/create",
        edit: "/college/{id}",
        update: "/college/{id}",
        delete: "/college/{id}",
    }
};
export const student = {
    api: {
        list: "/student/list",
        view: "/student/{id}",
    }
};
export const collegeStream = {
    api: {
        list: "/college/streams/list/{id}",
        view: "/college/stream/{id}",
    }
};
export const collegeProgramme = {
    api: {
        list: "/college/programmes/list/{id}",
        view: "/college/programme/{id}",
    }
};
export const collegeDiscipline = {
    api: {
        list: "/college/disciplines/list/{id}",
        view: "/college/discipline/{id}",
    }
};
export const studentResult = {
    api: {
        list: "/student/results/{id}",
        view: "/student/result/{id}",
    }
};
export const companysize = {
    api: {
        list: "/company-sizes/list",
        view: "/company-sizes/{id}",
    },
    web: {
        create: "/company-sizes/create",
        edit: "/company-sizes/{id}",
        update: "/company-sizes/{id}",
        delete: "/company-sizes/{id}",
    }
};


export const resourcetype = {
    api: {
        list: "/resource-type/list",
        view: "/resource-type/view/{id}",
    },
    web: {
        create: "/resource-type/create",
        edit: "/resource-type/edit/{id}",
        update: "/resource-type/update/{id}",
        delete: "/resource-type/delete/{id}",
    }
};
export const resource = {
    api: {
        list: "/resource/list",
        view: "/resource/view/{id}",
    },
    web: {
        create: "/resource/create",
        edit: "/resource/edit/{id}",
        status: "/resource/status/{id}",
        update: "/resource/update/{id}",
        delete: "/resource/delete/{id}",
    }
};
export const discount = {
    api: {
        list: "/discounts/list",
        view: "/discounts/{id}",
    },
    web: {
        create: "/discounts/create",
        edit: "/discounts/{id}",
        update: "/discounts/{id}",
        delete: "/discounts/{id}",
    }
};
export const discountUser = {
    api: {
        list: "/discount/users/list",
        view: "/discount/users/view/{id}",
    }
};
export const company = {
    api: {
        list: "/partner-agencies/list",
        view: "/partner-agencies/{id}",
    },
    web: {
        create: "/partner-agencies/create",
        edit: "/partner-agencies/{id}",
        update: "/partner-agencies/{id}",
        delete: "/partner-agencies/{id}",
    }
};
export const branch = {
    api: {
        list: "/branch/list",
        view: "/branch/view/{id}",
    },
    web: {
        create: "/branch/create",
        edit: "/branch/edit/{id}",
        update: "/branch/update/{id}",
        delete: "/branch/delete/{id}",
    }
};
// export const plan = {
//     api: {
//         list: "/plan/list",
//         view: "/plan/view/{id}",
//         update: "/plan/{id}",
//         delete: "/plan/{id}",
//     },
//     web: {
//         create: "/plan/create",
//         edit: "/plan/edit/{id}",
//         update: "/plan/update/{id}",
//         delete: "/plan/delete/{id}",
//     }
// };
//Resource Report List
export const ResourceReportList = {
    api: {
        list: "/resource/report/list/{id}",
        view: "/resource/report/view/{id}",
    },
    web: {
        create: "/resource/report/create",
        edit: "/resource/report/edit/{id}",
        update: "/resource/report/update/{id}",
        delete: "/resource/report/delete/{id}",
    }
};
//Resource Report List
export const ResourceResponseList = {
    api: {
        list: "/resource/response/list/{id}",
        view: "/resource/response/view/{id}",
    },
    web: {
        create: "/resource/response/create",
        edit: "/resource/response/edit/{id}",
        update: "/resource/response/update/{id}",
        delete: "/resource/response/delete/{id}",
    }
};
export const priority = {
    api: {
        list: "/priority/list",
        view: "/priority/view/{id}",
    },
    web: {
        create: "/priority/create",
        edit: "/priority/edit/{id}",
        update: "/priority/update/{id}",
        delete: "/priority/delete/{id}",
    }
};
export const duration = {
    api: {
        list: "/durations/list",
        view: "/durations/{id}",
    },
    web: {
        create: "/durations/create",
        edit: "/durations/{id}",
        update: "/durations/{id}",
        delete: "/durations/{id}",
    }
};
export const availability = {
    api: {
        list: "/availabilities/list",
        view: "/availabilities/{id}",
    },
    web: {
        create: "/availabilities/create",
        edit: "/availabilities/{id}",
        update: "/availabilities/{id}",
        delete: "/availabilities/{id}",
    }
};
export const experience = {
    api: {
        list: "/experiences/list",
        view: "/experiences/{id}",
    },
    web: {
        create: "/experiences/create",
        edit: "/experiences/{id}",
        update: "/experiences/{id}",
        delete: "/experiences/{id}",
    }
};

export const opportunityTypes = {
    api: {
        list: "/opportunities/types",
    },
    web: {
    }
};
export const skills = {
    api: {
        list: "/skills/list",
        view: "/skills/{id}",
    },
    web: {
        create: "/skills/create",
        edit: "/skills/{id}",
        update: "/skills/{id}",
        delete: "/skills/{id}",
    }
};

export const currencytype = {
    api: {
        list: "/currency-type/list",
        view: "/currency-type/view/{id}",
    },
    web: {
        create: "/currency-type/create",
        edit: "/currency-type/edit/{id}",
        update: "/currency-type/update/{id}",
        delete: "/currency-type/delete/{id}",
    }
};
export const socialplatform = {
    api: {
        list: "/social-platforms/list",
        view: "/social-platforms/{id}",
    },
    web: {
        create: "/social-platforms/create",
        edit: "/social-platforms/{id}",
        update: "/social-platforms/{id}",
        delete: "/social-platforms/{id}",
    }
};
export const state = {
    api: {
        list: "/states/list",
        view: "/states/{id}",
        filter: "/states/filter",
    },
    web: {
        create: "/states/create",
        edit: "/states/{id}",
        update: "/states/{id}",
        delete: "/states/{id}",
    }
};
export const stream = {
    api: {
        list: "/stream/list",
        view: "/stream/{id}",
        filter: "/stream/filter",
    },
    web: {
        create: "/stream/create",
        edit: "/stream/{id}",
        update: "/stream/{id}",
        delete: "/stream/{id}",
    }
};
export const discipline = {
    api: {
        list: "/discipline/list",
        view: "/discipline/{id}",
        filter: "/discipline/filter",
    },
    web: {
        create: "/discipline/create",
        edit: "/discipline/{id}",
        update: "/discipline/{id}",
        delete: "/discipline/{id}",
    }
};

export const city = {
    api: {
        list: "/cities/list",
        view: "/cities/{id}",
    },
    web: {
        create: "/cities/create",
        edit: "/cities/{id}",
        update: "/cities/{id}",
        delete: "/cities/{id}",
    }
};

export const industry = {
    api: {
        list: "/industries/list",
        view: "/industries/{id}",
        filter: "/industries/filter",
    },
    web: {
        create: "/industries/create",
        edit: "/industries/{id}",
        update: "/industries/{id}",
        delete: "/industries/{id}",
    }
};

export const industrytype = {
    api: {
        list: "/industry-type/list",
        view: "/industry-type/view/{id}",
    },
    web: {
        create: "/industry-type/create",
        edit: "/industry-type/edit/{id}",
        update: "/industry-type/update/{id}",
        delete: "/industry-type/delete/{id}",
    }
};

export const language = {
    api: {
        list: "/languages/list",
        view: "/languages/{id}",
    },
    web: {
        create: "/languages/create",
        edit: "/languages/{id}",
        update: "/languages/{id}",
        delete: "/languages/{id}",
    }
};
export const reportreason = {
    api: {
        list: "/report-reasons/list",
        view: "/report-reasons/{id}",
    },
    web: {
        create: "/report-reasons/create",
        edit: "/report-reasons/{id}",
        update: "/report-reasons/{id}",
        delete: "/report-reasons/{id}",
    }
};

export const notification = {
    api: {
        list: "/notifications/list",
        view: "/notifications/{id}",
    },
    web: {
        create: "/notifications/create",
        edit: "/notifications/{id}",
        update: "/notifications/{id}",
        delete: "/notifications/{id}",
    }
};
export const posts = {
    api: {
        list: "/posts/list",
        view: "/posts/view/{id}",  //get
    },
    web: {
        create: "/posts/create",
        edit: "/posts/edit/{id}",
        status: "/posts/status/{id}",
        update: "/posts/update/{id}",
        delete: "/posts/delete/{id}",
    }
};

export const postMedia = {
    api: {
        list: "/posts/media/list/{id}",
        view: "/posts/media/view/{id}",  //get
    },
    web: {
        create: "/posts/media/create",
        edit: "/posts/media/edit/{id}",
        update: "/posts/media/update/{id}",
        delete: "/posts/media/delete/{id}",
    }
};

//Post Report List
export const PostReportList = {
    api: {
        list: "/posts/report/list/{id}",
        view: "/posts/report/view/{id}",
    },
    web: {
        create: "/posts/report/create",
        edit: "/posts/report/edit/{id}",
        update: "/posts/report/update/{id}",
        delete: "/posts/report/delete/{id}",
    }
};
//Post Report List
export const PostResponseList = {
    api: {
        list: "/posts/response/list/{id}",
        view: "/posts/response/view/{id}",
    },
    web: {
        create: "/posts/response/create",
        edit: "/posts/response/edit/{id}",
        update: "/posts/response/update/{id}",
        delete: "/posts/response/delete/{id}",
    }
};
export const RecruitmentReportList = {
    api: {
        list: "/job-opening/report/list/{id}",
        view: "/job-opening/report/view/{id}",
    },
};
export const RecruitmentResponseList = {
    api: {
        list: "/job-opening/response/list/{id}",
        view: "/job-opening/response/view/{id}",
    },
};
export const users = {
    api: {
        list: "/users/list",
        view: "/users/view/{id}",  //get
    },
    web: {
        create: "/users/create",
        edit: "/users/edit/{id}",
        update: "/users/update/{id}",
        delete: "/users/delete/{id}",
    }
};

export const companies = {
    api: {
        list: "/users/profiles/list",
        view: "/users/view/{id}",  //get
    },
    web: {
        create: "/users/create",
        edit: "/users/edit/{id}",
        update: "/users/update/{id}",
        delete: "/users/delete/{id}",
    }
};
/*New Registered List*/
export const newRegisteredList = {
    api: {
        list: "/users/profiles/registered/list",
    },
    web: {
    }
};
/*New company Verification List*/
export const newVerificationList = {
    api: {
        list: "/users/profiles/verification/list",
    },
    web: {
    }
};

/* User Plan*/
export const userPlan = {
    api: {
        list: "",
        view: "/users/profiles/view-plan-permissions/{id}",
    },
    web: {
        create: "",
        edit: "/users/profiles/update-plan-permissions/{id}",
        delete: "",
    }
};

/*for filter in profile page*/
export const companiesTypes = {
    api: {
        list: "/company-types/option-list",
    },
    web: {
    }
};
/*for filter in profile page*/
export const timezones = {
    api: {
        list: "/timezones",
    },
    web: {
    }
};
//Give and Ask
export const gA = {
    api: {
        list: "/ga/list",
        view: "/ga/view/{id}",  //get
    },
    web: {
        create: "/ga/create",
        edit: "/ga/edit/{id}",
        update: "/ga/update/{id}",
        delete: "/ga/delete/{id}",
    }
};
//Give and Ask Categories
export const gAC = {
    api: {
        list: "/ga/categories/list",
        view: "/ga/categories/view/{id}",  //get
    },
    web: {
        create: "/ga/add/category",
        edit: "/ga/update/category/{id}",
        update: "/ga/update/{id}",
        delete: "/ga/delete/category/{id}",
    }
};

export const Recruitments = {
        api: {
            list: "/job-opening/list",
            view: "/job-opening/view/{id}",  //get
        },
        web: {
            create: "/job-opening/add",
            edit: "/job-opening/update/{id}",
            update: "/job-opening/update/{id}",
            status: "/job-opening/status/{id}",
            delete: "/job-opening/delete/{id}",
        }
};
export const Candidates = {
    api: {
        list: "/candidates/list",
        view: "/candidates/view/{id}",  //get
    },
    web: {
        create: "candidates/add",
        edit: "/candidates/update/{id}",
        update: "/candidates/update/{id}",
        status: "/candidates/status/{id}",
        delete: "/candidates/delete/{id}",
    }
};
export const CandidatesReportList = {
    api: {
        list: "/candidates/report/list/{id}",
        view: "/candidates/report/view/{id}",
    },
};
export const CandidatesResponseList = {
    api: {
        list: "/candidates/response/list/{id}",
        view: "/candidates/response/view/{id}",
    },
};

export const EExperience = {
    api: {
        list: "/e_experience/list",
        view: "/e_experience/{id}",  //get
    },
    web: {
        delete: "/e_experience/delete/{id}",
    }
};

/*Group made as per userTitle*/
export const UserTitles = {
    api: {
        list: "/groups/category-title/list",
        view: "/groups/category-title/{id}",  //get
    },
    web: {
        create: "/groups/category-title/create",
        edit:   "/groups/category-title/{id}",
        update: "/groups/category-title/{id}",
        delete: "/groups/category-title/{id}",
    }
};
export const EExperienceReport = {
    api: {
        list: "/e_experience/list-report/{id}",
        view: "/e_experience/report-view/{id}",  //get
    },
    web: {
        delete: "/e_experience/delete/{id}",
    }
};
export const EExperienceMedia = {
    api: {
        list: "/e_experience/media/list/{id}",
        view: "/e_experience/media-view/{id}",  //get
    },
    web: {
        delete: "/e_experience/delete/{id}",
    }
};
export const UID = {
    api: {
        list: "/uids/list",
        view: "/uids/{id}",  //get
    },
    web: {
        create: "/uids/create",
        edit: "/uids/{id}",
        update: "/uids/{id}",
        delete: "/uids/{id}",
    }
};

export const pricingTypes = {
    api: {
        list: "/pricing-types/list",
        view: "/pricing-types/{id}",  //get
    },
    web: {
        create: "/pricing-types/create",
        edit: "/pricing-types/{id}",
        update: "/pricing-types/{id}",
        delete: "/pricing-types/{id}",
    }
};

export const servicesTax = {
    api: {
        list: "/service-taxes/list",
        view: "/service-taxes/{id}",  //get
    },
    web: {
        create: "/service-taxes/create",
        edit: "/service-taxes/{id}",
        update: "/service-taxes/{id}",
        delete: "/service-taxes/{id}",
    }
};

export const transactions = {
    api: {
        list: "/transactions/list",
        view: "/transactions/view/{id}",  //get
    },
    web: {
        create: "/transactions/create",
        edit: "/transactions/edit/{id}",
        update: "/transactions/update/{id}",
    }
};

export const PANVERIFICATION = {
    api: {
    },
    web: {
        create: "/users/unique-id-num-verification",
    }
};
/*Create User for Admin Staff*/
export const staff = {
    api: {
        list: "/staff/list",
        view: "/staff/view/{id}",  //get
    },
    web: {
        create: "/staff/create",
        edit: "/staff/edit/{id}",
        update: "/staff/update/{id}",
        delete: "/staff/delete/{id}",
    }
};
export const modules = {
    api: {
        list: "/module/lists",
        view: "/module/view/{id}",  //get
    },
    web: {
        create: "/module/create",
        edit: "/module/edit/{id}",
        update: "/module/update/{id}",
        delete: "/module/delete/{id}",
    }
};
export const role = {
    api: {
        list: "/roles/list",
        view: "/roles/{id}",
        getRoles: "/roles/all/role",
    },
    web: {
        create: "/roles/create",
        edit: "/roles/{id}",
        update: "/roles/{id}",
        delete: "/roles/{id}",
    }
};

export const opportunity = {
    api: {
        list: "/opportunities/list",
        view: "/opportunities/view/{id}",  //get
    },
    web: {
        create: "/opportunities/create",
        edit: "/opportunities/edit/{id}",
        status: "/opportunities/status/{id}",
        update: "/opportunities/update/{id}",
        delete: "/opportunities/delete/{id}",
    }
};

export const opportunityMedia = {
    api: {
        list: "/opportunities/media/list/{id}",
        view: "/opportunities/media/view/{id}",  //get
    },
    web: {
        create: "/opportunities/media/create",
        edit: "/opportunities/media/edit/{id}",
        update: "/opportunities/media/update/{id}",
        delete: "/opportunities/media/delete/{id}",
    }
};


export const OpportunityReportList = {
    api: {
        list: "/opportunities/report/list/{id}",
        view: "/opportunities/report/view/{id}",
    },
    web: {
        create: "/opportunities/report/create",
        edit: "/opportunities/report/edit/{id}",
        update: "/opportunities/report/update/{id}",
        delete: "/opportunities/report/delete/{id}",
    }
};

export const OpportunityResponseList = {
    api: {
        list: "/opportunities/response/list/{id}",
        view: "/opportunities/response/view/{id}",
    },
    web: {
        create: "/opportunities/response/create",
        edit: "/opportunities/response/edit/{id}",
        update: "/opportunities/response/update/{id}",
        delete: "/opportunities/response/delete/{id}",
    }
};



export const pendingprofiles = {
    api: {
        list: "/users/profiles/pending/list",
        view: "/users/profiles/pending/view/{id}",  //get
        all: "/users/profiles/all/{user_id}",  //get
        postview: "/users/profiles/all/posts/view/{id}/{ids}",  //get
        opportunityview: "/users/profiles/all/opportunities/view/{id}/{ids}",  //get
        tripsview: "/users/profiles/all/business-traveller/view/{id}/{ids}",  //get
        resourceview: "/users/profiles/all/resource/view/{id}/{ids}",  //get
    },
    web: {

    }
};

export const profile = {
    api: {
        list: "/users/profiles/list",
        view: "/users/profiles/view/{id}",  //get
        all: "/users/profiles/all/{user_id}",  //get
        postview: "/users/profiles/all/posts/view/{id}/{ids}",  //get
        opportunityview: "/users/profiles/all/opportunities/view/{id}/{ids}",  //get
        tripsview: "/users/profiles/all/business-traveller/view/{id}/{ids}",  //get
        resourceview: "/users/profiles/all/resource/view/{id}/{ids}",  //get
    },
    web: {
        create: "/users/profiles/create",
        edit: "/users/profiles/edit/{id}",
        update: "/users/profiles/update/{id}",
        delete: "/users/profiles/delete/{id}",
        detail: "/users/company/details/{id}",
    }
};

export const feedbacks = {
    api: {
        list: "/feedbacks/list", //get
        view: "/feedbacks/{id}", //get
    },
    web: {
        delete: "/feedbacks/{id}", //delete
    }
};
export const inquiry = {
    api: {
        list: "/inquiry/list", //get
        view: "/inquiry/{id}", //get
    },
    web: {
        delete: "/inquiry/{id}", //delete
    }
};

export const contactus = {
    api: {
        list: "/contact-us/list", //get
        view: "/contact-us/{id}", //get
    },
    web: {
        delete: "/contact-us/{id}", //delete
    }
};

export const companyChildUsers = {
    api: {
        list: "/users/child/list/{id}",
        view: "/users/profiles/view/{id}",  //get
    },
    web: {
        create: "/users/profiles/create",
        edit: "/users/profiles/edit/{id}",
        update: "/users/profiles/update/{id}",
        delete: "/users/profiles/delete/{id}",
    }
};
export const companyUserLists = {
    api: {
        list: "/users/child/company-users-list/{id}",
    },
};



export const profileLinks = {
    api: {
        list: "/users/profiles/social-links/{id}",
        view: "/users/profiles/social-links/view/{id}",  //get
    },
    web: {
        create: "/users/profiles/create",
        edit: "/users/profiles/edit/{id}",
        update: "/users/profiles/social-links/update/{id}",
        delete: "/users/profiles/delete/{id}",
    }
};

export const profilerDocument = {
    api: {
        view: "/users/profiles/verify-profile/document/{id}",  //get
    },
    web: {}
};

export const profileVerifications = {
    api: {
        list: "/users/profiles/verification/{id}",
        view: "/users/profiles/verification/view/{id}",  //get
    },
    web: {
        create: "/users/profiles/verification/create",
        edit: "/users/profiles/verification/edit/{id}",
        update: "/users/profiles/verification/update/{id}",
        delete: "/users/profiles/verification/delete/{id}",
    }
};


export const verificationStatus = {
    api: {
        list: "/users/profiles/verification-status/list",
        view: "/users/profiles/verification-status/view/{id}",  //get
    },
    web: {
        create: "/users/profiles/verification-status/create",
        edit: "/users/profiles/verification-status/edit/{id}",
        update: "/users/profiles/verification-status/update/{id}",
        delete: "/users/profiles/verification-status/delete/{id}",
    }
};
export const profileSubIndustry = {
    api: {
        list: "/users/sub-industry/list",
    },
};
export const responsePriority = {
    api: {
        list: "/response/priority/list",
    },
};

export const reportType = {
    api: {
        list: "/list-report-type",
    },
    web: {
        create: "/add-report-type",
        edit: "/report-type/edit/{id}",
        update: "/update-report-type/{id}",
        delete: "/delete-report-type/{id}",
    }
};
export const userType = {
    api: {
        list: "/user-type-list",
    },
    web: {
        create: "/add-user-type",
        edit: "/user-type/edit/{id}",
        update: "/update-user-type/{id}",
        delete: "/delete-user-type/{id}",
    }
};
export const profileUpdate = {
    api: {
        detail: "/user/profile",
        update: "/user/corporate-user-profile",
        delete_document: "/user/delete-documents/{id}",

    },
    web: {
        list: "/edit_profile",
    }
};
export const editUser = {
    api: {
        detail: "/user/profile",
        update: "/user/profileUpdate",

    },
    web: {
        list: "/user_profile",
    }
};
export const changePass = {
    api: {
        create: "/change-password",
    },
    web: {
        list: "/change_password"
    }
};

export const adminProfile = {
    api: {
        list: "/my-profile/list/",
        view: "/users/my-profile/view/{id}",
    },
    web: {
        create: "/users/my-profile/create",
        edit: "/users/my-profile/edit/{id}",
        update: "/users/my-profile/update/{id}",
        delete: "",
    }
};

export const profileUpdateRequest = {
    api: {
        list: "/my-profile/list/",
        view: "/users/profile/update-request/view/{id}",
    },
    web: {
        update: "/users/profile/change-request-status",
    }
};

//Do not remove the admin word from url
export const menus = {
    api: {
        list: "/sidebar",
    },
    web: {}
};
export const page = {
    api: {
        detail: "/page/{id}",
    },
    web: {
        terms: "/#/terms",
    }
};
export const user = {
    api: {
        list: "/user-list",
    },
    web: {
        create: "/add-user",
        edit: "/user/edit/{id}",
        update: "/update-user/{id}",
        delete: "/delete-user/{id}",
    }
};

export const dashboardProfileList = {
    api: {
        list: "/dashboard/profile/list",
    },
    web: {}
};
export const dashboardRecentPostList = {
    api: {
        list: "/posts/recent",
    },
    web: {}
};
export const dashboardrecentOpportunityList = {
    api: {
        list: "/opportunities/recent",
    },
    web: {}
};
export const dashboardrecentResourceList = {
    api: {
        list: "/resource/recent",
    },
    web: {}
};
export const dashboardrecentInquiryList = {
    api: {
        list: "/inquiry/recent",
    },
    web: {}
};
export const dashboardrecentPostReportList = {
    api: {
        list: "/posts/report/recent",
    },
    web: {}
};
export const dashboardrecentOpportunityReportList = {
    api: {
        list: "/opportunities/report/recent",
    },
    web: {}
};
export const dashboardrecentResourceReportList = {
    api: {
        list: "/resource/report/recent",
    },
    web: {}
};
export const dashboardrecentGroupPostReportList = {
    api: {
        list: "/groups/post/recent",
    },
    web: {}
};
export const dashboardrecentGroupList = {
    api: {
        list: "/groups/recent",
    },
    web: {}
};
export const dashboardrecentPostResponseList = {
    api: {
        list: "/posts/response/recent",
    },
    web: {}
};
export const dashboardrecentTransactionList = {
    api: {
        list: "/transactions/recent",
    },
    web: {}
};
export const dashboardrecentPendingProfileList = {
    api: {
        list: "/users/profiles/pending/recent",
    },
    web: {}
};
export const dashboardrecentOpportunityResponseList = {
    api: {
        list: "/opportunities/response/recent",
    },
    web: {}
};
export const dashboardrecentResourceResponseList = {
    api: {
        list: "/resource/response/recent",
    },
    web: {}
};
export const dashboardNumberOfEntitiesList = {
    api: {
        list: "/dashboard/entities/list",
    },
    web: {}
};
export const rules = {
    api: {
        list: "/rules/list",
        view: "/rules/{id}",  //get
    },
    web: {
        create: "/rules/create",
        edit: "/rules/{id}",
        update: "/rules/{id}",
        delete: "/rules/{id}",
    }
};
export const cms = {
    api: {
        list: "/cms/list",
        view: "/cms/{id}",  //get
    },
    web: {
        create: "/cms/create",
        edit: "/cms/{id}",
        update: "/cms/{id}",
        delete: "/cms/{id}",
    }
};

export const emailTemplate = {
    api: {
        list: "/email-templates/list",
        view: "/email-templates/{id}",
    },
    web: {
        create: "/email-templates/create",
        edit: "/email-templates/{id}",
        update: "/email-templates/{id}",
        delete: "/email-templates/{id}",
    }
};

export const cmsMaster = {
    api: {
        list: "/pages/list",
        view: "/pages/{id}",  //get
    },
    web: {
        create: "/pages/create",
        edit: "/pages/{id}",
        update: "/pages/{id}",
        delete: "/pages/{id}",
    }
};

export const admin_setting = {
    api: {
        list: "/settings/list",
        view: "/settings/{id}",
    },
    web: {
        create: "/settings/create",
        edit: "/settings/{id}",
        update: "/settings/{id}",
        delete: "/settings/{id}",
    }
};
export const interviewers = {
    api: {
        list: "/users/interviewers/list/{id}",
        view: "/users/interviewers/{id}",
    },
    web: {
        create: "",
        edit: "",
        update: "",
        delete: "",
    }
};
export const platformRatting = {
    api: {
        list: "/website-ratting/list",
        view: "/website-ratting/view/{id}",
    },
    web: {
        create: "/website-ratting/create",
        edit: "/website-ratting/{id}",
        update: "/website-ratting/update/{id}",
        delete: "/website-ratting/{id}",
    }
};

export const reportsOpportunity = {
    api: {
        list: "/reports/opportunities",
        view: "",
    },
    web: {  }
};

export const otherCandidates = {
    api: {
        list: "/other-candidates/company",
        view: "/other-candidates/view/{id}",  //get
    },
    web: {
        create: "",
        edit: "",
        update: "",
        status: "",
        delete: "/other-candidates/delete/{id}",
    }
};
