module.exports = {
    title: "Company Type",
    crud: {
        create: "Create Company Type",
        edit: "Edit Company Type",
        view: "View Company Type",
    },
    table: {
        title: "Title",
        isActive: 'Active',
        type: 'Type',
    },
    form: {
        title: "Title",
        isActive: 'Active',
        type: 'Type',
    },
    validation: {
        required: {
            title: 'Title is required',
            type: 'Type is required',
        },
        minLength: {
            title: 'title must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}
