import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import Lang from 'vuejs-localization';
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store/store'
import { i18n } from './i18n'
import Vuelidate from 'vuelidate'
import '@fortawesome/fontawesome-free/css/all.css'
//import VeeValidate from 'vee-validate';
export const serverBus = new Vue();
//import "./assets/scss/radio_checkbox.css";

Vue.use(Vuelidate)
//Vue.use(VeeValidate);
import vSelect from 'vue-select'
import "vue-select/src/scss/vue-select.scss";

// import * as VeeValidate from 'vee-validate';
// import { Validator } from 'vee-validate';
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyBi7kbgkFiUUkVnmwb7aMnbi5ua2Cpc6-M",
        libraries: "places" // necessary for places input
    }
});


window.axios = require('axios');

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.component('v-select', vSelect)

Lang.requireAll(require.context('./lang', true, /\.js$/));
Vue.use(Lang);


router.beforeEach((to, from, next) => {
    store.commit("routeChange", "start");
    let permissions = store.state.user.permissions;

    let permission_flag = to.matched.some(record => typeof (record.meta.permission) != "undefined");

    let permission;
    if (permission_flag) {
        permission = to.matched.some(record => permissions.indexOf(record.meta.permission) >= 0);

    } else {
        permission = true;
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {


        if (store.state.user.isLogged === false) {
            next({
                path: '/signin'
            });
        } else if (!permission) {
            next({
                path: '/pages/401'
            });
        } else {
            next();
        }
    } else {
        next();
    }
})


Vue.prototype.$log = console.log.bind(console)
 // axios.defaults.baseURL = 'http://localhost:8081/admin';
// axios.defaults.baseURL = 'http://43.205.86.57:7000/admin';
//axios.defaults.baseURL = 'http://149.28.145.81/bizlynk-api-latest/public/admin';
//axios.defaults.baseURL = 'http://192.168.29.134:8082/admin';
axios.defaults.baseURL = 'https://wire.theresourceful.network/admin'
    const token = localStorage.getItem('token')
if (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer' + ' ' + token;
}
// axios.defaults.withCredentials = true;
Vue.config.productionTip = false
new Vue({
    el: '#app',
    router,
    i18n,
    store,
    icons,
    template: '<App/>',
    components: {
        App
    },
})
