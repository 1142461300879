module.exports = {
    'backend': {
        'access': {
            'users': {
                'activate': 'Activate',
                'change_password': 'Change Password',
                'clear_session': 'Clear Session',
                'confirm': 'Confirm',
                'deactivate': 'Deactivate',
                'delete_permanently': 'Delete Permanently',
                'login_as': 'Login As :user',
                'resend_email': 'Resend Confirmation E-mail',
                'restore_user': 'Restore User',
                'unconfirm': 'Un-confirm',
                'unlink': 'Unlink',
            },
        },
    },
    'emails': {
        'auth': {
            'confirm_account': 'Confirm Account',
            'reset_password': 'Reset Password',
        },
    },
    general: {
        'generate': 'Generate',
        'cancel': 'Cancel',
        'import': 'Import',
        'excel': 'Excel',
        'pdf': 'PDF',
        'print': 'Print',
        'back': 'Back',
        'copy': 'Copy',
        'export': 'Export',
        'continue': 'Continue',
        'final': 'Final',
        'active':'Active',
        'deactive':'DeActive',
        'suspend':'Suspend',
        crud: {
            view_group_users: 'View group detail',
            view_user: 'User Detail',
            active: 'Active',
            resend: 'Resend Mail',
            move: 'Move To Admin List',
            merge: 'Merge Skill',
            deactive: 'Deactive',
            candidates: 'Candidates',
            sent_mail: 'Sent the mail',
            view_feedback: 'View Feedback',
            view_resource: 'View Resources',
            create: 'Create',
            viewRequest: 'View',
            'create_next': 'Create & Next',
            'create_stay': 'Create & Stay',
            'search': 'Search',
            reset: 'Reset',
            delete: 'Delete',
            edit: 'Edit',
            'update': 'Update',
            view: 'View',
            show: 'View',
            attendees: 'Attendees',
            submit: 'Submit',
            cancel: 'Cancel',
            close: 'Close',
            save_next: 'Save & Next',
            save_stay: 'Save & Stay',
            update_next: 'Update & Next',
            update_stay: 'Update & Stay',
            upload_next: 'Upload & Next',
            upload_stay: 'Upload & Stay',
            next: 'Next',
            filter: 'Filter',
            finish: 'Finish',
            generate: 'Generate',
            save: 'Save',
            update_all: 'Update All',
            add: 'Add',
            manage_unavailability: 'Manage Unavailability',
            create_new: 'Create New',
            'generate_exam': 'Generate Exam',
            'save_finish': 'Save & Finish',
            'view_paper': 'View Exam Paper',
            'view_answer_sheet': 'View Answer Sheet',
            'login_as': 'Login As',
            'assign_subject': 'Assign Subject',
            'import': 'Import',
            'export': 'Export',
            'remove': 'Remove',
            'rack_cell': 'Rack & Cell',
            'issues': 'Issue Book',
            'download': 'Download',
            'approved': 'Approve Penalty',
            'cancel_order_details': 'Cancel Order Item',
            'generate_order': 'Generate Order',
            'completed': 'Completed',
            'pending': 'Pending',
            'create_template': 'Create Template',
            'update_template': 'Update Template',
            'upload': 'Upload',
            'payment': 'Payment',
            'switch': 'Switch',
        },
        'leaves': {
            'crud': {
                'add': 'Apply For Leave'
            }
        },

        'save': 'Save',
        'view': 'View',
        'view_resource': 'View Resources',
        'submit': 'Submit',
        'divisions': 'Divisions',
        'term': 'Terms',
        'category': 'Category',
        'chapters': 'Chapters',
        'questions': 'Questions',
        'assign_subjects': 'Assign Subjects',
    },
    export: {
        title: 'Export',
        pdf: 'PDF',
        excel: 'Excel',
    },
}
