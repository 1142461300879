module.exports = {
    title: "Give & Ask",
    crud: {
        create: "Create Category",
        edit: "Edit Category",
        post: "Post",
    },
    table: {
        registeredType: "Registered As",
        name: "Name",
        reason: "Reason",
        status: 'Active',
        message: 'Message',
        post: "Post",
        bookmark: 'Book Mark',
        firstName: 'Name',
        description: 'Description',
        added: 'Added at',
        type: 'Type',
        title: 'Title',
        company: 'Company',
    },
    tooltip: {
        view_comments: 'View comments of post',
    },
    detail: {
        post: 'Give And Ask Categories Post',
        polls: 'Polls',
        events: 'Events',
        groupUsers: 'Group Users',
        report: 'Give And Ask Categories Post Report',
    },
    form: {
        name: "Name",
        type: "Type",
        status: 'Active',
    },
    validation: {
        required: {
            name: 'Name is required.',
        }
    },
}
