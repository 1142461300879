module.exports = {
    title: 'Dashboard',
    form: {
        users: "Users",
        request: "Request",
        no_access: "Permission denied!",
        notification: "Notifications",
    },
    header: {
        profile: "Profile",
        logout: "Logout",
        term: "Terms & Conditions",
        policy: "Privacy Policy",
        change_password: "Change Password",
        recent_post: "Recent Posts",
        recent_project: "Recent Opportunities",
        recent_resource: "Recent Resources",
        recent_inquiry: "Recent Inquiry",
        recent_post_report: "Recent Post Report",
        recent_project_report: "Recent Opportunity Report",
        recent_resource_report: "Recent Resource Report",
        recent_group_post: "Recent Group Post",
        recent_group: "Recent Groups",
        recent_post_response: "Recent Post Response",
        recent_project_response: "Recent Opportunity Response",
        recent_resource_response: "Recent Resource Response",
        recent_pending_profile: "Recent Pending Profile",
        recent_transaction: "Recent Transaction",
        view_all: "View All",
        post_chart: "Post Chart",
    },
    recentPost: {
        detail: 'Owner Detail',
        description: 'Description',
        postTypeName: 'Post Type',
        status: 'Status'
    },
    recentOpportunity: {
        name: 'Name',
        detail: 'Owner Detail',
        description: 'Description',
    },
    recentResource: {
        firstName: 'First Name',
        detail: 'Owner Detail',
        title: 'Title',
    },
    recentInquiry: {
        name: 'Name',
        email: 'Email',
        description: 'Description',
    },
    recentPostReport: {
        post: 'Post',
        username: 'User Name',
        reason: 'Reason',
        description: 'Description',
    },
    recentOpportunityReport: {
        opportunity: 'Opportunity',
        username: 'User Name',
        reason: 'Reason',
        description: 'Description',
    },
    recentResourceReport: {
        firstName: 'First Name',
        username: 'User Name',
        reason: 'Reason',
        description: 'Description',
    },
    recentGroupPost: {
        name: 'Name',
        category: 'Category',
        message: 'Message',
        type: 'Type',
    },
    recentGroup: {
        name: 'Name',
        category: 'Category',
        message: 'Message',
        type: 'Type',
    },
    recentPostResponse: {
        post: 'Post',
        response: 'Response',
        username: 'User Name',
    },
    recentOpportunityResponse: {
        name: 'Name',
        category: 'Category',
        message: 'Message',
        type: 'Type',
    },
    recentResourceResponse: {
        name: 'Name',
        category: 'Category',
        message: 'Message',
        type: 'Type',
    },
    recentTransaction: {
        type: 'Registered As',
        company: 'Name',
        purchase: 'Purchase',
    },
    recentPendingProfile: {
        type: 'Type',
        company: 'Company',
    },
}
